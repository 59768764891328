import React from "react";
import { AvatarProps, DataTestIds } from "./avatar.interface";
import { StyledAvatar } from "./avatar.styled";

export const Avatar = (
    {
        avatarImageUrl,
        size = 100,
        testId = DataTestIds.AVATAR_IMAGE
    }: AvatarProps
) => {
    return (
        <StyledAvatar size={size} src={avatarImageUrl} data-testid={testId} />
    );
};

Avatar.displayName = "Avatar";
