import styled from "styled-components";
import { Checkbox, CheckboxProps } from "@mantine/core";

import { colors } from "lib/palette";

export const CHECKBOX_ANIMATION_CLASS = "animated";

export const StyledCheckboxContainer = styled(Checkbox) <CheckboxProps>`
    display: flex;

    .mantine-Checkbox-input {
        position: relative;
        width: 20px;
        height: 20px;
        border: 1px solid ${colors.blue_3};
        background-color: ${colors.blue_4};
        border-radius: 3px;
        transition: none;

        ${({ disabled }) => !disabled && "cursor: pointer;"}
    }

    .mantine-Checkbox-input.${CHECKBOX_ANIMATION_CLASS}{
        ${({ disabled }) => !disabled && `
            animation: flicker 1.5s infinite alternate;
        `}
    }

    @keyframes flicker {
        0%, 30%, 60%, 90% {
            box-shadow: 0 0 2px 2px ${colors.yellow_1};
        }
       
        15%, 45%, 75%, 100% {        
            box-shadow: none;
        }    
    }

    .mantine-Checkbox-input:checked {
        background-color: ${colors.blue_4};
        position: relative;

        ::after {
            content: "";
            display: inline-block;
            width: 12px;
            height: 11px;
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.18651 6.90546L10.1566 0L12 0.853708L4.27349 11L0 5.66265L1.37522 4.39843L4.18651 6.90546Z' fill='%237D4BE8'/%3E%3C/svg%3E");
        }
    }

    .mantine-Checkbox-inner {
        svg {
            display: none;
        }
    }

    .mantine-Checkbox-input:disabled {
        border: none;
        background-color: ${colors.black_4};

        &:checked {
            &::after {
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.18651 6.90546L10.1566 0L12 0.853708L4.27349 11L0 5.66265L1.37522 4.39843L4.18651 6.90546Z' fill='%23D9D9D9'/%3E%3C/svg%3E");
            }
        }
    }
`;
