import React, { useMemo } from "react";
import { useMediaQuery } from "@mantine/hooks";

import { Button } from "components/atoms/button/button.component";
import { breakpoints } from "lib/breakpoints";
import { Icon } from "../icon/icon.component";
import { Icons } from "../icon/icon.interface";
import { BUTTONS_DIRECTION } from "./warning-modal.constants";
import { testIds, WarningModalProps } from "./warning-modal.interface";
import {
    AttentionBlock,
    StyledButtonBlock,
    StyledModal,
    StyledModalCloseButton,
    StyledWarningModalButtonsBlock,
    StyledWarningModalHeading,
    StyledWarningModalMessage,
} from "./warning-modal.styled";
import { ButtonSize } from "components/atoms/button/button.interface";

export const WarningModal = (
    {
        opened,
        title,
        message,
        content,
        buttonsDirection = BUTTONS_DIRECTION.row,
        okButtonText,
        cancelButtonText,
        onClose,
        onOk,
        onCancel,
        okButtonIsLoading,
        cancelButtonIsLoading,
        okButtonVariant = "primary",
        cancelButtonVariant = "outlined",
        okButtonTestId,
        cancelButtonTestId,
        showWarningIcon = true,
        testId = testIds.warningModal,
    }: WarningModalProps
) => {
    const warningModalContent = useMemo(
        () => (
            <>
                <StyledModalCloseButton
                    onClick={onClose}
                    data-testid={testIds.closeFormButton}
                >
                    <Icon name={Icons.closeSvg} />
                </StyledModalCloseButton>

                <AttentionBlock>
                    {showWarningIcon && (
                        <Icon name={Icons.warningYellowIconSvg} />
                    )}
                    <StyledWarningModalHeading>
                        {title}
                    </StyledWarningModalHeading>
                </AttentionBlock>

                {message && (
                    <StyledWarningModalMessage>
                        {message}
                    </StyledWarningModalMessage>
                )}
                {content && content}
                <StyledWarningModalButtonsBlock
                    buttonsDirection={buttonsDirection}
                >
                    {cancelButtonText && (
                        <StyledButtonBlock>
                            <Button
                                isFullWidth
                                onClick={onCancel}
                                variant={cancelButtonVariant}
                                loading={cancelButtonIsLoading}
                                size={ButtonSize.MEDIUM}
                                data-testid={cancelButtonTestId}
                                showTitle
                            >
                                {cancelButtonText}
                            </Button>
                        </StyledButtonBlock>
                    )}
                    {okButtonText && onOk && (
                        <StyledButtonBlock>
                            <Button
                                isFullWidth
                                loading={okButtonIsLoading}
                                onClick={onOk}
                                variant={okButtonVariant}
                                size={ButtonSize.MEDIUM}
                                data-testid={okButtonTestId}
                                showTitle
                            >
                                {okButtonText}
                            </Button>
                        </StyledButtonBlock>
                    )}
                </StyledWarningModalButtonsBlock>
            </>
        ),
        [
            onClose,
            showWarningIcon,
            title,
            message,
            content,
            buttonsDirection,
            cancelButtonText,
            onCancel,
            cancelButtonVariant,
            cancelButtonIsLoading,
            cancelButtonTestId,
            okButtonText,
            onOk,
            okButtonIsLoading,
            okButtonVariant,
            okButtonTestId,
        ],
    );

    const isMobile = useMediaQuery(`(max-width: ${`${breakpoints.sm}px`})`);

    return (
        <>
            {isMobile ? (
                <StyledModal
                    opened={opened}
                    onClose={onClose}
                    centered
                    withCloseButton={false}
                    transition="slide-up"
                    data-testid={testId}
                >
                    {warningModalContent}
                </StyledModal>
            ) : (
                <StyledModal
                    opened={opened}
                    onClose={onClose}
                    centered
                    transition="slide-left"
                    withCloseButton={false}
                    data-testid={testId}
                >
                    {warningModalContent}
                </StyledModal>
            )}
        </>
    );
};

WarningModal.displayName = "WarningModal";
