import { toast, ToastContent, ToastOptions } from "./toast";

export const DEFAULT_ERROR_MESSAGE = "Permission denied. Contact your administrator.";

export const warningToast = (message?: ToastContent, options?: ToastOptions) => {
    return toast(message || DEFAULT_ERROR_MESSAGE, {
        ...options,
        type: "warning",
    });
};
