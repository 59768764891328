import React from "react";
import { useTranslation } from "next-i18next";

import { NodeRenderProps } from "../../node-render";
import {
    NodeContainer,
    NodeControlLeftBlock,
} from "components/molecules/tree/node-render/components/node-render.styled";
import { NodeItemIcon } from "components/molecules/tree/node-render/components/node-item-icon/node-item-icon";
import { CollapseTrigger } from "components/molecules/tree/node-render/components/collapse-trigger/collapse-trigger";
import { NodeTitle } from "components/molecules/tree/node-render/components/node-title/node-title";
import { Label } from "components/atoms/label/label.component";
import { ACTIVE, INACTIVE } from "lib/constants/status";
import { UserAccess } from "lib/constants/user-access";
import { Checkbox } from "components/atoms/checkbox/checkbox.component";
import {
    StyledCheckboxBlock,
    StyledNodeControlContent,
    StyledNodeControlRightBlock,
    StyledStatusBlock,
} from "../../node-render.styled";
import { testIds } from "./unit.interface";

export const Unit = ({
    id,
    opened,
    nodeProps,
    isRoot,
    hasAccessToRoot,
    toggleCollapse,
    onChange,
    hasChildren,
}: NodeRenderProps) => {
    const { t } = useTranslation("users");

    return (
        <NodeContainer>
            {hasChildren && (
                <CollapseTrigger opened={opened} onClick={toggleCollapse} />
            )}
            <StyledNodeControlContent>
                <NodeControlLeftBlock>
                    <NodeItemIcon isRoot={!!isRoot} />
                    <NodeTitle title={nodeProps.title} />
                </NodeControlLeftBlock>
                <StyledNodeControlRightBlock>
                    <StyledStatusBlock>
                        <Label
                            status={
                                nodeProps.companyStatusData.status
                                    ? ACTIVE
                                    : INACTIVE
                            }
                        >
                            {t(
                                nodeProps.companyStatusData.message[0],
                                nodeProps.companyStatusData.message[1],
                            )}
                        </Label>
                    </StyledStatusBlock>

                    <StyledCheckboxBlock>
                        {((isRoot && hasAccessToRoot) || !isRoot) && (
                            <Checkbox
                                onChange={() => onChange(id)}
                                checked={
                                    nodeProps.userAccess ===
                                        UserAccess.DIRECT ||
                                    nodeProps.userAccess ===
                                        UserAccess.INHERITED
                                }
                                data-testid={`${
                                    isRoot ? testIds.rootCard : testIds.unitCard
                                }-${id}`}
                            />
                        )}
                    </StyledCheckboxBlock>
                </StyledNodeControlRightBlock>
            </StyledNodeControlContent>
        </NodeContainer>
    );
};
