import React from "react";
import { ILabelProps } from "./label.interface";
import { StyledLabel } from "./label.styled";

export const Label = (
    {
        children,
        ...props
    }: ILabelProps
) => {
    return <StyledLabel {...props}>{children}</StyledLabel>;
};
