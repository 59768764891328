import { cloneDeep } from "lodash";

import { UserAccess } from "lib/constants/user-access";
import { traverseDepthFirst } from "lib/utils/tree/traverse-depth-first";
import { ItemsById, TreeData } from "../../types";

export type ChangeAccessForAllChildrenParams = {
    id: string;
    nextAccess: UserAccess;
    items: ItemsById;
    treeData: TreeData;
};

export const changeAccessForAllChildren = ({
    id,
    nextAccess,
    items,
    treeData,
}: ChangeAccessForAllChildrenParams) => {
    const cloneItems = cloneDeep(items);

    traverseDepthFirst({
        treeData,
        rootId: id,
        callback: (nodeId) => {
          if (nodeId !== id) {
            const node = cloneItems[nodeId];
            if (node) {
                node.access.user_access = nextAccess;
            }
          }
        },
    });

    return cloneItems;
};
