import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { PermissionsService } from "lib/api/api";
import { RpcStatus, V1UpdateAccessesResponse } from "../common/data-contracts";

type Options = UseMutationOptions<
    V1UpdateAccessesResponse,
    RpcStatus,
    {
        userId: string;
        body: {
            grant?: string[];
            revoke?: string[];
        };
    }
>;

export function useUpdateUserAccessMutation(options?: Options) {
    return useMutation(
        async (args: {
            userId: string;
            body: {
                grant?: string[];
                revoke?: string[];
            };
        }) => {
            return (
                await PermissionsService.permissionServiceUpdateAccesses(
                    args?.userId,
                    args?.body,
                )
            ).data;
        },
        options,
    );
}
